export const URLS_PAGES = {
  MARKET_PLACE: {
    STAGGING: {
      HOME: 'https://marketplace.kriptonmarket.com',
    },
    PRODUCTION: {
      HOME: 'https://marketplace.kriptonmarket.com',
    },
  },
  SERVICES: {
    STAGGING: {
      HOME: 'https://staging.kripton.la',
    },
    PRODUCTION: {
      HOME: 'https://kripton.la',
    },
  },
  CASH: {
    STAGGING: {
      HOME: 'https://staging.kripton.la/comercios',
    },
    PRODUCTION: {
      HOME: 'https://kripton.la/comercios',
    },
  },
  LANDING: {
    STAGGING: {
      QUOTATION: 'https://staging-info.kriptonmarket.com/quotation',
    },
    PRODUCTION: {
      QUOTATION: 'https://info.kriptonmarket.com/quotation',
    },
  },
};
